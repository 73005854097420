import { useLoaderData } from '@remix-run/react';

import { SubscriptionCancelConfirm } from '../components/Product/SubscriptionCancel';
import { apiService } from '../services/api-service';

export async function clientLoader() {
  const resp = await apiService.analytics.getPreUnsubscribeSummary();

  return {
    summary: resp.data,
  };
}

export function Component() {
  const { summary } = useLoaderData<typeof clientLoader>();

  return <SubscriptionCancelConfirm {...summary} />;
}
